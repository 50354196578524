import {
  PartitionOutlined,
  UserSwitchOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  FormatPainterOutlined,
  ProfileOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  ControlOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  EuroCircleOutlined,
  ShoppingCartOutlined,
  PercentageOutlined,
  BarChartOutlined,
  FundOutlined,
  TableOutlined,
  BankOutlined,
  ContactsOutlined,
  FundViewOutlined,
  OneToOneOutlined,
  ScheduleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "base",
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.base",
    icon: DatabaseOutlined,
    breadcrumb: true,
    rank: 1,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/home`,
        title: "sidenav.dashboard",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
        rank: 2,
      },
      {
        key: "pier-dashboard",
        path: `${APP_PREFIX_PATH}/pier-dashboard`,
        title: "sidenav.pier_dashboard",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
        rank: 1,
      },

      //************************************************************************* */
      // Consultas
      {
        key: "entries",
        path: `${APP_PREFIX_PATH}/entry`,
        title: "sidenav.entry",
        icon: ProfileOutlined,
        breadcrumb: true,
        opened: true,
        rank: 2,
        submenu: [
          {
            key: "entries-new",
            path: `${APP_PREFIX_PATH}/entry/new`,
            title: "sidenav.new_entry",
            icon: AppstoreAddOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "entries-list",
            path: `${APP_PREFIX_PATH}/entry/list`,
            allowed_paths: [{ path: `${APP_PREFIX_PATH}/entry/manage/.*`, title: "sidenav.manage_entry" }],
            title: "sidenav.entries_list",
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
        ],
      },

      //************************************************************************* */
      // Serviços
      {
        key: "services",
        path: `${APP_PREFIX_PATH}/service`,
        title: "sidenav.service",
        icon: ReconciliationOutlined,
        breadcrumb: true,
        rank: 1,
        submenu: [
          {
            key: "services-list",
            path: `${APP_PREFIX_PATH}/service/list`,
            allowed_paths: [{ path: `${APP_PREFIX_PATH}/service/edit/.*`, title: "sidenav.manage_service" }],
            title: "sidenav.service-list",
            icon: ProfileOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 3,
          },
          {
            key: "services-embarkation-map",
            path: `${APP_PREFIX_PATH}/service/embarkation-map`,
            title: "sidenav.service-embarkation-map",
            icon: FundViewOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 3,
          },
          {
            key: "services-occupation-map",
            path: `${APP_PREFIX_PATH}/service/occupation-map`,
            title: "sidenav.service-occupation-map",
            icon: OneToOneOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 1,
          },
          {
            key: "services-accounting-day-map",
            path: `${APP_PREFIX_PATH}/service/accounting-day-map`,
            title: "sidenav.service-accounting-day-map",
            icon: ScheduleOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 1,
          },
          {
            key: "services-regular-schedule",
            path: `${APP_PREFIX_PATH}/service/regular-schedule`,
            title: "sidenav.service-regular-schedule",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 3,
          },
        ],
        opened: false,
      },

      //************************************************************************* */
      // Faturação
      {
        key: "invoicing",
        path: `${APP_PREFIX_PATH}/invoicing`,
        title: "sidenav.invoicing",
        icon: EuroCircleOutlined,
        breadcrumb: true,
        rank: 2,
        submenu: [
          {
            key: "invoicing-invoicing",
            path: `${APP_PREFIX_PATH}/invoicing/invoicing`,
            title: "sidenav.invoicing_invoicing",
            icon: EuroCircleOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },

          {
            key: "invoicing-entities-full-list",
            path: `${APP_PREFIX_PATH}/invoicing/entities-list`,
            title: "sidenav.invoicing_entities_full_list",
            icon: TeamOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "invoicing-entity-list",
            path: `${APP_PREFIX_PATH}/invoicing/entity/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/invoicing/entity/edit/.*`, title: "sidenav.invoicing_edit_entity" },
              { path: `${APP_PREFIX_PATH}/invoicing/entity/new`, title: "sidenav.invoicing_new_entity" },
            ],
            title: "sidenav.invoicing_entity_list",
            icon: TeamOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "invoicing-services-conference-new",
            path: `${APP_PREFIX_PATH}/invoicing/services-conference/new`,
            title: "sidenav.invoicing_services_conference", //Conferência de Serviços
            icon: FundOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "invoicing-services-conference-reports",
            path: `${APP_PREFIX_PATH}/invoicing/services-conference/list`,
            title: "sidenav.invoicing_services_conference_list", //Conferência de Serviços
            icon: BarChartOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
        ],
        opened: false,
      },

      //************************************************************************* */
      // Reconciliação Bancária
      {
        key: "bank-reconciliation",
        path: `${APP_PREFIX_PATH}/bank-reconciliation`,
        allowed_paths: [{ path: `${APP_PREFIX_PATH}/admin/edit/.*`, title: "sidenav.bank-reconciliation" }],
        title: "sidenav.bank-reconciliation",
        icon: BankOutlined,
        breadcrumb: true,
        rank: 1,
        submenu: [
          {
            key: "bank-reconciliation-dashboard",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/dashboard`,
            title: "sidenav.bank-reconciliation-dashboard",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-reconciliation",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/reconciliation`,
            title: "sidenav.bank-reconciliation-reconciliation",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-movements",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/movements`,
            title: "sidenav.bank-reconciliation-movements",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-transactions",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/transactions`,
            title: "sidenav.bank-reconciliation-transactions",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 1,
          },
        ],
        opened: false,
      },
      {
        key: "bank-marketing",
        path: `${APP_PREFIX_PATH}/bank-marketing`,
        allowed_paths: [{ path: `${APP_PREFIX_PATH}/admin/marketing/.*`, title: "sidenav.bank-marketing" }],
        title: "sidenav.bank-marketing",
        icon: BankOutlined,
        breadcrumb: true,
        rank: 1,
        submenu: [
          {
            key: "bank-marketing-dashboard",
            path: `${APP_PREFIX_PATH}/bank-marketing/dashboard`,
            title: "sidenav.bank-marketing-dashboard",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-marketing-dashboard",
            path: `${APP_PREFIX_PATH}/bank-marketing/dashboard`,
            title: "sidenav.bank-marketing-dashboard",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
        ],
      },
      /*XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
      {
        key: "global",
        path: `${APP_PREFIX_PATH}/global`,
        title: "sidenav.global",
        icon: DatabaseOutlined,
        breadcrumb: true,
        rank: 1,
        submenu: [
          {
            key: "global-avatar-list",
            path: `${APP_PREFIX_PATH}/global/avatar/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/avatar/edit/.*`, title: "sidenav.edit_avatar" },
              { path: `${APP_PREFIX_PATH}/global/avatar/new`, title: "sidenav.new_avatar" },
            ],
            title: "sidenav.avatar_list",
            icon: UserSwitchOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "global-qualification-list",
            path: `${APP_PREFIX_PATH}/global/qualification/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/qualification/edit/.*`, title: "sidenav.edit_qualification" },
              { path: `${APP_PREFIX_PATH}/global/qualification/new`, title: "sidenav.new_qualification" },
            ],
            title: "sidenav.qualification_list",
            icon: PartitionOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "global-pier-list",
            path: `${APP_PREFIX_PATH}/global/pier/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/pier/edit/.*`, title: "sidenav.edit_pier" },
              { path: `${APP_PREFIX_PATH}/global/pier/new`, title: "sidenav.new_pier" },
            ],
            title: "sidenav.pier_list",
            icon: EnvironmentOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "global-pier-locations-list",
            path: `${APP_PREFIX_PATH}/global/pier-locations/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/pier-locations/edit/.*`, title: "sidenav.edit_pier" },
              { path: `${APP_PREFIX_PATH}/global/pier-locations/new`, title: "sidenav.new_pier" },
            ],
            title: "sidenav.pier_locations_list",
            icon: EnvironmentOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "global-equipment-list",
            path: `${APP_PREFIX_PATH}/global/equipment/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/equipment/edit/.*`, title: "sidenav.edit_equipment" },
              { path: `${APP_PREFIX_PATH}/global/equipment/new`, title: "sidenav.new_equipment" },
            ],
            title: "sidenav.equipment_list",
            icon: ControlOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "global-staff-list",
            path: `${APP_PREFIX_PATH}/global/staff/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/staff/new`, title: "sidenav.new_staff" },
              { path: `${APP_PREFIX_PATH}/global/staff/edit/.*`, title: "sidenav.edit_staff" },
            ],
            title: "sidenav.staff_list",
            icon: FormatPainterOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },

          {
            key: "global-classification-list",
            path: `${APP_PREFIX_PATH}/global/classification/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/classification/edit/.*`, title: "sidenav.edit_classification" },
              { path: `${APP_PREFIX_PATH}/global/classification/new`, title: "sidenav.new_classification" },
            ],
            title: "sidenav.classification_list",
            icon: ContactsOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "global-vouchers",
            path: `${APP_PREFIX_PATH}/global/vouchers`,
            title: "sidenav.vouchers",
            icon: PercentageOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 1,
          },
          {
            key: "global-products-list",
            path: `${APP_PREFIX_PATH}/global/products`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/global/products/list`, title: "sidenav.list_products" },
              { path: `${APP_PREFIX_PATH}/global/products/manage/.*`, title: "sidenav.manage_entity" },
            ],
            title: "sidenav.products",
            icon: ShoppingCartOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-tables-companies",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/tables/companies`,
            title: "sidenav.bank-reconciliation-tables-companies",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-tables-banks",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/tables/banks`,
            title: "sidenav.bank-reconciliation-tables-banks",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-tables-accounts",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/tables/accounts`,
            title: "sidenav.bank-reconciliation-tables-accounts",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-tables-cards",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/tables/cards`,
            title: "sidenav.bank-reconciliation-tables-cards",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-tables-entities",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/tables/entities`,

            title: "sidenav.bank-reconciliation-tables-entities",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-tables-categories",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/tables/categories`,
            title: "sidenav.bank-reconciliation-tables-categories",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
          {
            key: "bank-reconciliation-tables-documents-physical-locations",
            path: `${APP_PREFIX_PATH}/bank-reconciliation/tables/documents-physical-locations`,
            title: "sidenav.bank-reconciliation-tables-documents-physical-locations",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
        ],
      },

      {
        key: "tools",
        path: `${APP_PREFIX_PATH}/tools`,
        allowed_paths: [{ path: `${APP_PREFIX_PATH}/tools/.*`, title: "sidenav.tools" }],
        title: "sidenav.tools",
        icon: SettingOutlined,
        breadcrumb: true,
        rank: 2,
        submenu: [
          {
            key: "tools-sync-products",
            path: `${APP_PREFIX_PATH}/tools/sync-products`,
            title: "sidenav.tools_sync_products",
            icon: SettingOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },

          {
            key: "tools-export-emails",
            path: `${APP_PREFIX_PATH}/tools/export-emails`,
            title: "sidenav.tools_export_emails",
            icon: ContactsOutlined,
            breadcrumb: true,
            submenu: [],
            rank: 2,
          },
        ],
        opened: false,
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
